.service-container {
  padding: 20px;
  height: 100%;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.body {
  background-color: #E6D2E8;
  min-height: 100vh;
  height: auto;
}

