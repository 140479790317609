body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: auto;
  }
  
  .home-container {
    display: grid;
    grid-template-rows: auto 1fr; /* Navbar height is auto, video takes the rest */
    height: 100vh;
  }
  
  .video-container {
    position: relative;
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Send video behind the content */
  }
  
  .content {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .content {
      font-size: 1.2em; /* Adjust font size for mobile */
      padding: 10px;
    }
  
    h1 {
      font-size: 2em; /* Adjust heading size for mobile */
    }
  }
  
  @media (max-width: 480px) {
    .content {
      font-size: 1em; /* Further adjust font size for smaller screens */
      padding: 5px;
    }
  
    h1 {
      font-size: 1.5em; /* Further adjust heading size for smaller screens */
    }
  }
  